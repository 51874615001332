<!-- home.component.html -->
 <div class="container">
  <div class="class-home">

  
<div class="home-container flex">
  <!-- Contenido de HomeComponent -->
  <div class="home-content flex-1 p-4">
    <h1
            class="hidden lg:block text-4xl sm:text-4xl text-primary md:text-4xl lg:text-6xl xl:text-6xl font-extrabold primary-color animate__animated animate__zoomInUp animate__delay-1s">
            PITZON.<span class="text-white font-bold">IO</span>
        </h1>
    <p class="text-xl text-white mt-2 animate__animated animate__bounce font-bold">¡Una solución justo a tiempo!</p>
    <p class="text-xl text-white mt-6 animate__animated animate__bounce font-bold">
      En <b>PITZON</b>, nos encargamos de co  nectar tu necesidad con talleres estandarizados que cuentan con altos
      niveles de atención y calidad en servicio, brindándote la confianza que te mereces.
    </p>
<!-- home.component.html -->
 <div>
  <button (click)="goToAssistant()" class="mt-6 bg-primary text-black text-xl font-bold py-2 px-3 rounded-full animate-pulse">
    ASISTENCIA VIRTUAL GRATUITA
</button>
    <div class="mt-6">
      <!-- Lista de servicios -->
      <div class="mt-6 block animate__animated animate__backInDown">
            <span class="bg-dark p-1 rounded-full text-white text-xs m-1 inline-block" *ngFor="let ser of services">{{ser}}</span>
        </div>
    </div>
    <div class="mt-">
      <p>Al hacer uso de nuestros servicios, aceptas automáticamente nuestros
        <a href="#" class="text-white">Términos y Condiciones</a> así como nuestras
        <a href="#" class="text-white">Políticas de Privacidad</a>.
      </p>
    </div>
  </div>
</div>
</div>
</div>

