import { Routes } from '@angular/router';
import { HomeLayout } from './layouts/home-layout';
import { CoverLoginComponent } from './core/auth/login/login.component';
import { AssistantComponent } from './components/assistant/assistant.component';
import { Error404Component } from './components/error404/error404.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeLayout,
  },
  {
    path: 'assistant',
    component: AssistantComponent,
  },
  {
    path: 'login',
    component: CoverLoginComponent,
  },
  {
    path: '**',
    component: Error404Component,
  },
  //{
  // path: '**',  // Ruta comodín para redirigir a 404
  //   redirectTo: '',
  //   pathMatch: 'full'
  // },
];
