import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => {
          return route.data.pipe(
            map((routeData: any) => {
              const title = routeData['title'];
              return { title };
            }),
          );
        }),
        tap((data: any) => {
          let title = data.title;
          title = (title ? title + ' | ' : '') + 'P I T Z O N ';
          this.titleService.setTitle(title);
        }),
      )
      .subscribe();
  }
}
