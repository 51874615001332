<!-- Map -->
<div *ngIf="(isMobile && !showMap) || !isMobile" @slideDownUp class="h-screen w-full md:w-6/12 relative">
  <!-- Google Map -->
  <google-map height="100%" width="200%" [center]="coords" [zoom]="14">
    <map-marker *ngIf="coords" [position]="coords" [title]="'Tu ubicación'" [icon]="userIconPosition" [options]="mapOptions"></map-marker>

    <map-marker
      *ngFor="let workshop of workshops"
      [position]="workshop.coords"
      [title]="workshop.companyName"
      [icon]="workShopIconPosition"
      #marker="mapMarker"
      (mapClick)="openWorkshopInfo(marker, workshop)"
    ></map-marker>

    <map-info-window>
      <div class="panel h-full max-w-[352px] overflow-hidden border-0 p-0">
        <div>
          <div class="mb-6 flex items-center justify-center pt-4" *ngIf="selectedWorkshop?.images!.length > 0">
              <app-workshop-photos [items]="selectedWorkshop?.images"></app-workshop-photos>
             
          </div>
          <div class="flex items-center justify-between text-white"></div>
        </div>

        <div class="p-0">
          <div class="mb-6 text-2xl text-center font-bold text-gray-600">
            <strong>{{ selectedWorkshop ? selectedWorkshop.companyName : '' }}</strong>
          </div>
          <div class="mb-5">
            <span class="rounded-full bg-[#1b2e4b] px-4 py-1.5 text-xs uppercase text-white"> Categoría: {{ selectedWorkshop?.levelName }} </span>
          </div>
          <div class="mb-5 space-y-1">
            <div class="flex items-center justify-between">
              <p class="font-semibold text-[#515365]">
                Servicios:
                <br />
                <span class="m-1 inline-block rounded-full bg-dark p-1 text-xs text-white">Cambio de aceite</span>
                <span class="m-1 inline-block rounded-full bg-dark p-1 text-xs text-white">Revisión de neumáticos</span>
                <span class="m-1 inline-block rounded-full bg-dark p-1 text-xs text-white">Inspección de suspensión</span>
                <span class="m-1 inline-block rounded-full bg-dark p-1 text-xs text-white">Reparación de motores</span>
              </p>
            </div>
          </div>
          <div class="flex justify-around px-2 text-center">
            <button type="button" class="btn btn-primary text-dark" (click)="schedule(selectedWorkshop!)">AGENDAR CITA</button>
          </div>
        </div>
      </div>
    </map-info-window>
  </google-map>
</div>
