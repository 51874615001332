<header></header>
<body>
<div class="bg-gradient-to-b from-white to-gray-300">
    <div class="flex flex-col justify-center items-center h-screen">
      
      <!-- Contenedor de la imagen principal -->
      <!-- <div class="mb-8">
        <img
          src="../../../assets/images/.png"
          alt="Logo"
          class="max-w-full h-auto"
        />
      </div> -->
  
      <!-- Contenedor de la imagen de error 404 y el botón -->
      <div class="flex flex-col items-center">
        <img
          src="../../../assets/images/pitzon404.gif"
          alt="ERROR 404"
          class="rounded-lg shadow-lg mb-4"
        />
      </div>
  
      <!-- Botón debajo de la imagen de error -->
      <!-- <a [routerLink]="['../home']" [routerLinkActive]="['router-link-active']">
        <button class="transition ease-in-out delay-150 bg-blue-400 hover:-translate-y-1 hover:scale-110 hover:bg-Third duration-300">
          Regresar
        </button>
      </a> -->
    </div>
    
    <sidebar></sidebar>
  </div>
</body>