import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent {
  services: string[] = [
    'Cambio de aceite',
    'Revisión de neumáticos',
    'Inspección de suspensión',
    'Ajuste de carburadores',
    'Revisión y cambio de frenos',
    'Rotación de neumáticos',
    'Revisión de líquidos',
    'Ajuste de embrague',
    'Verificación de luces',
    'Mantenimientos preventivos',
    'Cambios de pastillas',
    'Diagnóstico electrónico',
    'Lavado y encerado',
    'Reemplazo de baterias',
    'Reparación de motores',
  ];

  items = [
    'https://rmservicosautomotivos.com.br/imagens/noticias/56_1_1.jpg',
    'https://tse3.mm.bing.net/th?id=OIP.oevHw_XbzeC1xgO1vQqbggHaE6&pid=Api&P=0&h=18.jpg',
    'https://tse4.mm.bing.net/th?id=OIP.ooynGjMXNga_mZuw9QoMdgHaEK&pid=Api&P=0&h=180',
  ];

  constructor(private router: Router) {
    // do nothing
  }
  goToAssistant() {
    this.router.navigate(['/assistant']);
  }
}
