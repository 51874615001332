import { Component } from '@angular/core';

@Component({
  selector: 'app-error404',
  // standalone: true,
  // imports: [Router],
  templateUrl: './error404.component.html',
  styleUrl: './error404.component.css',
})
export class Error404Component {}
